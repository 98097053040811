<template>
  <div class="body">
    <div>
      <img src="../assets/icons/sour-logo.png" class="logo" />

      <h1 id="carbon-calculator">Carbon Calculator</h1>
      <h2 id="introduction">Introduction</h2>
      <p>
        Welcome to SOUR's carbon calculator. This calculator measures the
        greenhouse gas impacts of construction activities in terms of carbon
        dioxide equivalency (CO2e). It does this by calculating the embodied
        carbon of constructed spaces and its supply chain activities during
        construction. This tool was developed to estimate emissions based upon
        updated emission datasets, in order to raise awareness on the industry's
        carbon footprint and stimulate action. Through this calculator, SOUR
        offsets its estimate project carbon footprint through <a href="https://www.cooleffect.org/" target="_blank"
          >Cool Effect,<Redirect width="16px" heigh="16px" fill="#333"
        /></a>, a
        nonprofit organization which matches more than 90% of its donations with
        scientifically verified carbon reducing projects.
      </p>
      <h2 id="method">Method</h2>
      <p>
        SOUR carbon calculator is cradle to (construction site) gate scope. This
        corresponds with Module A1 to A3 in the EU standards, EN 15978 and EN
        15804. This tool incorporates the Carbon Leadership Forum's global
        <a
          href="https://carbonleadershipforum.org/embodied-carbon-benchmark-study-1/"
          target="_blank"
          >Embodied Carbon Benchmark Database (ECBD)<Redirect
            width="16px"
            heigh="16px"
            fill="#333" /></a
        > of over 1,000 existing buildings. The North America Based carbon
        stocks of soil were delineated based upon ecoregion from a recent (2021)
        published study in Nature. "Regional environmental controllers influence
        continental scale soil carbon stocks and future carbon dynamic." The
        tool maps an approximate 500 miles radius from US major cities to
        various ecoregions as defined by the EPA.
      </p>
      <h2 id="user-guide">User Guide</h2>
      <p>
        On the "Data Input" page, enter in values for each calculation. Data
        input will either be a numerical value in units given on the worksheet
        or from a selected drop down list. For data cells that a user wishes to
        enter a null or zero values, either enter zero for a numerical value
        cell or choose the blank line item option from the drop-down list. The
        calculator results are shown on the right hand side of the "Data Input"
        worksheet. The categories of data (Building, Landscape, Supply Chain)
        can be turned off by entering a zero value.
      </p>

      <div class="btn add-new" style="margin: 1rem 0" @click="$emit('close')">
        <h5>Start</h5>
      </div>
      <ArowRight
        class="cancel arrow-right"
        style="width: 30px; height: 30px; top: 2rem"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ArowRight: require("../assets/icons/arow-right.svg"),
    Redirect: require("../assets/icons/redirect.svg"),
  },
};
</script>

<style lang="sass" scoped>
.body
  z-index: 20
  padding: 1rem 20%
  overflow-x: hidden
  background-color: white
  @media screen and (max-width: 1000px)
    padding: 1rem 5%
  @media screen and (min-width: 1920px)
    .btn.add-new
      padding: 1rem
    p
      font-size: 1.2em
  .logo
    width: 180px
    max-width: auto
  > div
    padding: 2rem
    position: relative
  .arrow-right
    padding: 20px
    box-sizing: content-box
  a[href]
    font-weight: 900

</style>