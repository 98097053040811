<template>
  <footer id="footer" class="footer">
    <div class="section">
      <div class="wrapper m0-40">
        <div class="container">
          <div class="w-layout-grid grid">
            <a
              href="https://sour.studio/"
              id="w-node-_91cf32fe-cb5d-e0ba-c851-c3c977f6b445-08c2cae8"
              class="brand w-nav-brand"
              ><img
                src="https://uploads-ssl.webflow.com/604b762ce035a888f896f338/604b762ce035a8cc8296f345_Asset%201.png"
                loading="lazy"
                sizes="(max-width: 479px) 18vw, (max-width: 767px) 11vw, (max-width: 991px) 8vw, 5vw"
                height="30"
                srcset="
                  https://uploads-ssl.webflow.com/604b762ce035a888f896f338/604b762ce035a8cc8296f345_Asset%25201-p-500.png  500w,
                  https://uploads-ssl.webflow.com/604b762ce035a888f896f338/604b762ce035a8cc8296f345_Asset%25201-p-800.png  800w,
                  https://uploads-ssl.webflow.com/604b762ce035a888f896f338/604b762ce035a8cc8296f345_Asset%201.png         1244w
                "
                alt=""
                class="logo"
            /></a>
            <div id="w-node-c252130b-29be-e6e6-3b22-cf98660c75fd-08c2cae8">
              Ready to start your next project? Drop us a line or stop by our
              office. We are open for creative minds and collaborations!
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper m0-40">
        <div class="container"><div class="divider"></div></div>
      </div>
      <div class="wrapper">
        <div class="container">
          <div class="grid">
            <div
              id="w-node-_7aef9112-0012-fa4c-702c-a32b08c2cb16-08c2cae8"
              class="footer-items"
            >
              <h2 class="footer-heading">On Social</h2>
              <ul role="list" class="w-list-unstyled">
                <li>
                  <a
                    href="https://www.instagram.com/whatissour/"
                    target="_blank"
                    class="footer-link"
                    >instagram<strong>/whatissour</strong></a
                  >
                </li>
                <li>
                  <a
                    href="https://twitter.com/thisissour"
                    target="_blank"
                    class="footer-link"
                    >twitter<strong>/thisissour</strong></a
                  >
                </li>
                <li>
                  <a
                    href="https://medium.com/what-is-sour#"
                    target="_blank"
                    class="footer-link"
                    >medium<strong>/this-is-sour</strong></a
                  ><a
                    href="https://www.linkedin.com/company/whatissour/"
                    target="_blank"
                    class="footer-link"
                    >linkedin<strong>/whatissour</strong></a
                  >
                </li>
              </ul>
            </div>
            <div
              id="w-node-_7aef9112-0012-fa4c-702c-a32b08c2caeb-08c2cae8"
              class="footer-items"
            >
              <h2 class="footer-heading">Visit Us</h2>
              <ul role="list" class="w-list-unstyled">
                <li>
                  <a
                    href="https://www.google.com/maps/dir//nyc+sour+studio+flushing+ave/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x89c25bc93e7ef3d9:0x4752c277f69d6f80?sa=X&amp;ved=2ahUKEwjLy_LfjLjvAhWRC2MBHfeaA2IQ9RcwAHoECAgQBQ"
                    target="_blank"
                    class="footer-link m0-20"
                    ><strong>NYC: </strong><br />63 Flushing Ave<br />Building
                    280, Suite 504<br />Brooklyn, NY 11205</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.google.com/maps/dir//ny+sour+studio/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x14cab9e0d7bc0b6d:0x4d119adb61ee3ad5?sa=X&amp;ved=2ahUKEwjs46nWjLjvAhUS8BQKHVmQD2EQ9RcwFHoECB0QBA"
                    target="_blank"
                    class="footer-link"
                    ><strong>IST:<br /></strong>Asmali Mescid Mh. Nergiz Sk.
                    <br />Karakteke Is Hani Kat: 2 8/5<br />Beyoglu, Istanbul,
                    3440 Turkey</a
                  >
                </li>
              </ul>
            </div>
            <div
              id="w-node-_7aef9112-0012-fa4c-702c-a32b08c2cb03-08c2cae8"
              class="footer-items"
            >
              <h2 class="footer-heading">Contact Us</h2>
              <ul role="list" class="w-list-unstyled">
                <li>
                  <a
                    href="mailto:hello@sour.studio?subject=Hello%20SOUR!"
                    class="footer-link"
                    >hello@sour.studio</a
                  >
                </li>
                <li>
                  <a href="tel:+17188752233" class="footer-link"
                    ><strong>NYC:</strong> +1 (718) 875-2233<br /></a
                  ><a href="tel:+902122439045" class="footer-link"
                    ><strong>IST:</strong>&nbsp;+90 (212) 243 90 45<br
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper m40">
      <div class="container"><div class="divider"></div></div>
    </div>
    <div class="wrapper m0-40">
      <div class="container">
        <div class="center-text">© {{ new Date().getFullYear() }} All rights reserved.</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@import '../assets/styles/footer.css'
.footer
  scroll-snap-align: start
</style>