var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"body",on:{"scroll":function($event){return _vm.calcScrollIndex($event.target.scrollTop)}}},[_c('header',{class:{ scrolled: _vm.scrollTop > 0 }},[_vm._m(0)]),_c('div',{class:{ progress: true, scrolled: _vm.scrollTop > 0 }},[_c('div',{staticClass:"track"},[_c('div',{staticClass:"bar",style:({ width: (_vm.scrollIndex < 2 ? (_vm.scrollIndex+1)*25 : 100) + '%' })})]),_vm._l((_vm.progress.items),function(item,name,index){return _c('div',{key:item.icon,class:{
          item: true,
          done: _vm.scrollIndex >= index || _vm.scrollIndex >= 2,
        }},[_c(item.icon,{tag:"component"}),_c('h3',[_vm._v(_vm._s(name))])],1)})],2),_c('form',[_c('div',{staticClass:"form-group",attrs:{"tabindex":"1"}},[_c('h2',[_vm._v("Building")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.BUILDING_SIZE.TOTAL_SQUARE_FEET),expression:"BUILDING_SIZE.TOTAL_SQUARE_FEET",modifiers:{"number":true}}],attrs:{"autocomplete":"off","type":"number","name":"bs-tsf","required":""},domProps:{"value":(_vm.BUILDING_SIZE.TOTAL_SQUARE_FEET)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.BUILDING_SIZE, "TOTAL_SQUARE_FEET", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"unit"},[_vm._v("Meters")]),_c('label',{attrs:{"for":"bs-tsf"}},[_vm._v("Total Square Feet")])]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.BUILDING_SIZE.STORIES_ABOVE_GROUND),expression:"BUILDING_SIZE.STORIES_ABOVE_GROUND",modifiers:{"number":true}}],attrs:{"autocomplete":"off","type":"number","required":""},domProps:{"value":(_vm.BUILDING_SIZE.STORIES_ABOVE_GROUND)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.BUILDING_SIZE, "STORIES_ABOVE_GROUND", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"unit"},[_vm._v("Quantity")]),_c('label',{attrs:{"for":"bs-sag"}},[_vm._v("Stories Above Ground")])]),_c('div',{staticClass:"button-group"},[_c('div',{staticClass:"btn prev disabled",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollTo(-1)}}},[_c('ArrowUp'),_vm._v(" Previous ")],1),_c('div',{staticClass:"btn next",class:{
              disabled: false,
            },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollTo(1)}}},[_vm._v(" Next "),_c('ArrowDown')],1)])]),(true || _vm.progress.items.Building.done)?_c('div',{staticClass:"form-group",attrs:{"tabindex":"2"}},[_vm._m(1),_c('Select',{attrs:{"list":_vm.locations,"option":{ key: 'City' },"search":true,"label":'Location',"selected":_vm.LANDSCAPE.LOCATION},on:{"select":function($event){_vm.LANDSCAPE.LOCATION = $event;
            _vm.LANDSCAPE.LOCATION.Nature = _vm.ecoregions.filter(function (ecoregion) { return ecoregion.Name.toUpperCase().includes(
                _vm.LANDSCAPE.LOCATION.Nature.toUpperCase()
              ); }
            )[0];}}}),_c('Select',{attrs:{"list":_vm.vegetations,"option":{ key: 'Name' },"search":true,"label":'Existing Vegetation',"selected":_vm.LANDSCAPE.EXISTING_VEGETATION},on:{"select":function($event){_vm.LANDSCAPE.EXISTING_VEGETATION = $event}}}),_c('Select',{attrs:{"list":_vm.vegetations,"option":{ key: 'Name' },"search":true,"label":'Planted Vegetation',"selected":_vm.LANDSCAPE.PLANTED_VEGETATION},on:{"select":function($event){_vm.LANDSCAPE.PLANTED_VEGETATION = $event}}}),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.LANDSCAPE.SQUAREFEET_LANDSCAPE_INSTALLED),expression:"LANDSCAPE.SQUAREFEET_LANDSCAPE_INSTALLED",modifiers:{"number":true}}],attrs:{"autocomplete":"off","type":"number","name":"l-sli","required":""},domProps:{"value":(_vm.LANDSCAPE.SQUAREFEET_LANDSCAPE_INSTALLED)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.LANDSCAPE, "SQUAREFEET_LANDSCAPE_INSTALLED", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"unit"},[_vm._v("Quantity")]),_c('label',{attrs:{"for":"l-sli"}},[_vm._v("Squarefeet Landscape Installed")])]),_c('div',{staticClass:"button-group"},[_c('div',{staticClass:"btn prev",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollTo(0)}}},[_c('ArrowUp'),_vm._v(" Previous ")],1),_c('div',{staticClass:"btn next",class:{
              disabled: false,
            },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollTo(2)}}},[_vm._v(" Next "),_c('ArrowDown')],1)])],1):_vm._e(),(true || _vm.progress.items.Landscape.done)?_c('div',{staticClass:"form-group",attrs:{"tabindex":"3"}},[_c('h2',[_vm._v("Supply Chain")]),_c('transition-group',{staticClass:"container",attrs:{"tag":"div"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"leave":_vm.leave}},_vm._l((_vm.SUPPLY_CHAIN.SHIPMENTS),function(SHIPMENT,name){return _c('div',{key:name,staticClass:"shipment-group"},[_c('h3',{staticClass:"legend"},[_vm._v(_vm._s(name))]),_c('Cancel',{key:'cancel-btn-' + name,staticClass:"cancel",staticStyle:{"left":"12px","top":"12px"},on:{"click":function($event){_vm.$set(_vm.SUPPLY_CHAIN.SHIPMENTS, name, undefined);
                delete _vm.SUPPLY_CHAIN.SHIPMENTS[name];}}}),_vm._l((SHIPMENT.ITEMS),function(material,i){return _c('div',{key:material.id,class:{ material: true, selected: material.Name !== 'None' }},[_c('div',{key:"inner-group",staticClass:"inner-group"},[_c('Select',{attrs:{"list":_vm.materials,"option":{ key: 'Name' },"search":false,"label":'Material',"selected":_vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i]},on:{"select":function($event){return Object.assign(
                      _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i],
                      $event
                    )}}}),_c('div',{staticClass:"input-group sc-mq"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(
                      _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].quantity
                    ),expression:"\n                      SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].quantity\n                    ",modifiers:{"number":true}}],attrs:{"autocomplete":"off","type":"number","name":"sc-mq"},domProps:{"value":(
                      _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].quantity
                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i], "quantity", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"unit"},[_vm._v("Square Meter")]),_c('label',{attrs:{"for":"sc-mq"}},[_vm._v("Quantity")])]),(
                    ['MDF', 'Marble'].includes(
                      _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].Name
                    )
                  )?_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(
                      _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].width
                    ),expression:"\n                      SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].width\n                    ",modifiers:{"number":true}}],attrs:{"autocomplete":"off","type":"number","name":"sc-mh"},domProps:{"value":(
                      _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].width
                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i], "width", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"unit"},[_vm._v("Meters")]),_c('label',{attrs:{"for":"sc-mh"}},[_vm._v("Width")])]):_vm._e()],1),_c('transition-group',{class:{
                  legs: true,
                  empty: material.legs.length == 0,
                },attrs:{"tag":"div"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"leave":_vm.leave}},[_c('h4',{key:"legend",staticClass:"legend"},[_vm._v("Transportation")]),_vm._l((_vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i]
                    .legs),function(leg,b){return _c('div',{key:leg.id,staticClass:"leg",style:(("z-index: " + (_vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs.length - b)))},[_c('h5',[_vm._v("Leg "+_vm._s(b + 1))]),_c('Cancel',{staticClass:"cancel",on:{"click":function($event){return _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs.splice(b, 1)}}}),_c('Select',{attrs:{"list":_vm.transportations,"option":{ key: 'Mode' },"search":false,"label":'Transportation Mode',"selected":_vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b]},on:{"select":function($event){return Object.assign(
                        _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b],
                        $event
                      )}}}),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(
                        _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b].distance
                      ),expression:"\n                        SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b].distance\n                      ",modifiers:{"number":true}}],attrs:{"autocomplete":"off","type":"number","name":"sc-ld"},domProps:{"value":(
                        _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b].distance
                      )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b], "distance", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"unit",domProps:{"textContent":_vm._s(
                        _vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b].Unit
                      )}}),_c('label',{attrs:{"for":"sc-mq"}},[_vm._v("Distance")])])],1)}),(_vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs.length)?_c('div',{key:"redirect-url",staticStyle:{"max-width":"fit-content","font-size":"12px"}},[_c('a',{attrs:{"href":"https://www.distancefromto.net/","target":"_blank"}},[_c('b',[_vm._v("Refer to this website to calculate distance "),_c('Redirect',{attrs:{"width":"16px","heigh":"16px","fill":"#333"}})],1)])]):_vm._e()],2),(_vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs.length < 2)?_c('div',{staticClass:"btn add-new",attrs:{"title":"Add new leg"},on:{"click":function($event){_vm.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs.push(_vm.newLeg())}}},[_c('span',[_vm._v("Add new leg")])]):_vm._e()],1)})],2)}),0),(Object.keys(_vm.SUPPLY_CHAIN.SHIPMENTS).length < 25)?_c('div',{key:"add-btn",staticClass:"btn add-new btn-material",staticStyle:{"margin-bottom":"50px"},attrs:{"title":"Add new shipment"},on:{"click":function($event){return _vm.addNewShipment()}}},[_c('span',[_vm._v("Add Shipment")])]):_vm._e(),_c('div',{staticClass:"button-group"},[_c('div',{staticClass:"btn prev",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollTo(1)}}},[_c('ArrowUp'),_vm._v(" Previous ")],1),_c('div',{staticClass:"btn next",class:{
              disabled: false,
            },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollTo(3)}}},[_vm._v(" Results "),_c('ArrowDown')],1)])],1):_vm._e(),_c('div',{staticClass:"form-group",attrs:{"tabindex":"4"}},[_c('h2',[_vm._v("Results")]),_c('div',{staticClass:"input-group"},[_c('input',{attrs:{"autocomplete":"off","type":"number","readonly":"","name":"tb","valid":""},domProps:{"value":_vm.TOTAL_BUILDING().toFixed(1)}}),_c('div',{staticClass:"unit"},[_vm._v("Tonnes CO2 equiv")]),_c('label',{attrs:{"for":"tb"}},[_vm._v("Total Building")])]),_c('div',{staticClass:"input-group"},[_c('input',{attrs:{"type":"text","readonly":"","name":"ts","valid":"","placeholder":"Not ready"},domProps:{"value":_vm.TOTAL_SITE().toFixed(1)}}),_c('div',{staticClass:"unit"},[_vm._v("Tonnes CO2 equiv")]),_c('label',{attrs:{"for":"ts"}},[_vm._v("Total Site")])]),_c('div',{staticClass:"input-group has-sub-items"},[_c('input',{attrs:{"type":"text","readonly":"","name":"tsc","valid":"","placeholder":"Not ready"},domProps:{"value":_vm.TOTAL_SUPPLY_CHAIN().value.toFixed(1)}}),_c('div',{staticClass:"unit"},[_vm._v("Tonnes CO2 equiv")]),_c('label',{attrs:{"for":"tsc"}},[_vm._v("Total Supply Chain")]),_c('div',{staticClass:"sub-items"},_vm._l((_vm.TOTAL_SUPPLY_CHAIN().MATERIALS.filter(
                function (m) { return m.Material !== 'None'; }
              )),function(ITEM,i){return _c('div',{key:i,staticClass:"item"},[_c('span',[_vm._v(" "+_vm._s(ITEM.Material)+" "),_c('small',{staticClass:"legs-text"},_vm._l((ITEM.Transportations),function(t,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(t)+" "),_c('Next',{attrs:{"width":"8px","height":"8px"}})],1)}),0)]),_c('span',[_vm._v(_vm._s(ITEM["Sum Tonnes Co2"]))])])}),0)]),_c('div',{staticClass:"input-group"},[_c('input',{attrs:{"type":"text","readonly":"","name":"tb","valid":"","placeholder":"Not ready"},domProps:{"value":(
                _vm.TOTAL_BUILDING() +
                _vm.TOTAL_SITE() +
                _vm.TOTAL_SUPPLY_CHAIN().value
              ).toFixed(3)}}),_c('div',{staticClass:"unit"},[_vm._v("Tonnes CO2 equiv")]),_c('label',{attrs:{"for":"tb"}},[_vm._v("Summary")])]),_c('div',{staticClass:"button-group"},[_c('div',{staticClass:"btn prev",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollTo(2)}}},[_c('ArrowUp'),_vm._v(" Previous ")],1),_c('div',{staticClass:"btn next",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.resetForm()}}},[_vm._v(" Reset "),_c('Reset')],1)])]),_c('Footer')],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/icons/sour-logo.png")}}),_c('h1',[_vm._v("Carbon Calculator")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',[_vm._v("Landscape "),_c('small',{staticStyle:{"color":"#999"}},[_vm._v("(US Only)")])])}]

export { render, staticRenderFns }