<template>
  <div id="app">
    <transition name="fade">
      <Introduction @close="introduced = true" v-if="!introduced" />
    </transition>
    <transition name="fade">
      <Body v-if="introduced" />
    </transition>
  </div>
</template>

<script>
import Body from "./components/body";
import Introduction from "./components/introduction";
import "scroll-behavior-polyfill"

export default {
  name: "App",
  components: {
    Introduction,
    Body,
  },
  data() {
    return {
      introduced: false,
    };
  },
};
</script>

<style lang="sass">
$primary: lighten(black, 70%)
$secondary: lighten(black, 95%)

@font-face
  font-family: 'Decima'
  font-weight: 900
  src: url('../src/assets/fonts/DecimaPro-Bold.otf')
@font-face
  font-family: 'Decima'
  font-weight: 200
  src: url('../src/assets/fonts/DecimaPro-Light.otf')
@font-face
  font-family: 'Decima'
  font-weight: 300
  src: url('../src/assets/fonts/DecimaPro-Regular.otf')


input
  font-size: 16px
body, html
  overflow: hidden
  position: fixed
  left: 0
  right: 0
  bottom: 0
  top: 0
  display: block
::selection
  background-color: $primary
  color: #fff

#app, input::placeholder, input
  overflow: hidden
  font-family: 'Decima', 'IBM Plex Mono', Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.body
  overflow-y: auto
  overflow-x: hidden
  -webkit-overflow-scrolling: touch
  scroll-behavior: smooth
  // scroll-snap-type: y mandatory
  height: 100%
  width: 100vw
  left: 0
  right: 0
  bottom: 0
  top: 0
  position: absolute
  &:hover::-webkit-scrollbar-thumb
    background-color: $primary
  &::-webkit-scrollbar
    width: 4px
    &-track
      background-color: white
    &-thumb
      border-radius: 4px
      background-color: white
      &:hover
        background-color: darken($primary, 20%)

.cancel
  position: absolute
  right: 0
  top: 4px
  width: 12px
  height: 12px
  fill: black
  cursor: pointer
  &:hover
    fill: #999
    &:hover ~ *
      background-color: #eee !important
.btn
  align-items: center
  display: inline-flex
  border-radius: 2px
  border: 2px solid $secondary
  padding: 4px
  z-index: 2
  cursor: pointer
  box-shadow: 0 0 4px transparent
  transition: border .2s ease, box-shadow .2s
  &:hover
    background-color: #fafafa
  &:active
    outline: none
    border: 2px solid $primary
    box-shadow: 0 0 12px #eee
.btn:not(.add-new):active
  background-color: #444 !important
.btn.add-new:active
  background-color: #ccc !important

.input-group
  z-index: 2
.input-group, .custom-select > .selected-item
  position: relative
  padding: 15px 0
  @media screen and (max-width: 430px)
    width: -webkit-fill-available    
  input
    padding: 6px 4px
    width: 300px
    border: 1px solid #eee
    min-width: auto
    outline: none
    -webkit-appearance: none
    @media screen and (max-width: 430px)
      width: 100%
    &:focus
      border: 1px solid #ccc
    &[type="radio"]
      // width: 0 !important
      height: 30px
      margin: 0
      display: flex
      align-items: center
      background-color: white
      border-radius: 2px
      border: 1px solid $secondary
      cursor: pointer
      &:checked
        border: 1px solid $primary
      &:checked::before
        border: 1px solid #000
        border-radius: 50%
        background-image: radial-gradient(black 0, black 48%, #fff 50%, #fff 100%)
      &::before
        content: ""
        display: block
        width: 14px
        height: 14px
        background-color: #fff
        border: 1px solid $primary
        position: absolute
        left: 4px
        border-radius: 20%
        transition: border-radius .2s ease
      & + label
        height: auto
        margin: 0
        display: inline-block
        left: 0
        background-color: #fff !important
        text-align: left
        transform: translate(25px, -25px) !important
  input:valid ~ label,
  input[readonly] ~ label,
  &.selected > label
    transform: translate(8px, -44px)
    background-color: white
    font-size: 12px
    color: black
  input + label,
  &:not(.selected) > label
    position: absolute
    transition: transform .2s ease, background-color .2s, font-size .2s
    transform: translate(5px, -26px)
    width: fit-content
    height: 14px
    font-size: 14px
    // text-transform: uppercase
    color: #999
    pointer-events: none
  .unit
    position: absolute
    font-size: 12px
    font-weight: 700
    // text-transform: uppercase
    right: 6px
    top: 0
    background-color: #fff
    color: #aaa

@media screen and (min-width: 1920px)
  .input-group, .custom-select > .selected-item
    padding: 1.25rem 0
    input, > .item
      width: 400px
      font-size: 20px
    > .item
      padding: 10px 4px !important
    input + label,
    &:not(.selected) > label
      transform: translate(8px, -1.9rem)
      font-size: 16px
    input:valid ~ label,
    input[readonly] ~ label,
    &.selected label
      transform: translate(8px, -54px)
      font-size: 14px
    .unit
      font-size: 16px
      top: 5px

.fade-enter-active
  opacity: 1
  transition: opacity 1s ease, transform 1s ease
.fade-leave-active
  opacity: 0
  transform: translateX(40px)
  transition: opacity 1s ease, transform 1s ease
</style>
