<template>
  <div>
    <div class="body" @scroll="calcScrollIndex($event.target.scrollTop)">
      <header :class="{ scrolled: scrollTop > 0 }">
        <div class="group">
          <img src="../assets/icons/sour-logo.png" class="logo" />
          <h1>Carbon Calculator</h1>
        </div>
      </header>
      <div :class="{ progress: true, scrolled: scrollTop > 0 }">
        <div class="track">
          <div class="bar" :style="{ width: (scrollIndex < 2 ? (scrollIndex+1)*25 : 100) + '%' }" />
        </div>
        <div
          :class="{
            item: true,
            done: scrollIndex >= index || scrollIndex >= 2,
          }"
          v-for="(item, name, index) of progress.items"
          :key="item.icon"
        >
          <component :is="item.icon" />
          <h3>{{ name }}</h3>
        </div>
      </div>

      <form>
        <div class="form-group" tabindex="1">
          <h2>Building</h2>
          <div class="input-group">
            <input
              autocomplete="off"
              type="number"
              name="bs-tsf"
              v-model.number="BUILDING_SIZE.TOTAL_SQUARE_FEET"
              required
            />
            <div class="unit">Meters</div>
            <label for="bs-tsf">Total Square Feet</label>
          </div>

          <div class="input-group">
            <input
              autocomplete="off"
              type="number"
              v-model.number="BUILDING_SIZE.STORIES_ABOVE_GROUND"
              required
            />
            <div class="unit">Quantity</div>
            <label for="bs-sag">Stories Above Ground</label>
          </div>

          <div class="button-group">
            <div class="btn prev disabled" @click.prevent.stop="scrollTo(-1)">
              <ArrowUp /> Previous
            </div>
            <div
              class="btn next"
              :class="{
                disabled: false,
              }"
              @click.prevent.stop="scrollTo(1)"
            >
              Next <ArrowDown />
            </div>
          </div>
        </div>

        <div
          class="form-group"
          tabindex="2"
          v-if="true || progress.items.Building.done"
        >
          <h2>Landscape <small style="color: #999">(US Only)</small></h2>

          <Select
            :list="locations"
            :option="{ key: 'City' }"
            :search="true"
            :label="'Location'"
            @select="
              LANDSCAPE.LOCATION = $event;
              LANDSCAPE.LOCATION.Nature = ecoregions.filter((ecoregion) =>
                ecoregion.Name.toUpperCase().includes(
                  LANDSCAPE.LOCATION.Nature.toUpperCase()
                )
              )[0];
            "
            :selected="LANDSCAPE.LOCATION"
          />
          <Select
            :list="vegetations"
            :option="{ key: 'Name' }"
            :search="true"
            :label="'Existing Vegetation'"
            @select="LANDSCAPE.EXISTING_VEGETATION = $event"
            :selected="LANDSCAPE.EXISTING_VEGETATION"
          />
          <Select
            :list="vegetations"
            :option="{ key: 'Name' }"
            :search="true"
            :label="'Planted Vegetation'"
            @select="LANDSCAPE.PLANTED_VEGETATION = $event"
            :selected="LANDSCAPE.PLANTED_VEGETATION"
          />
          <div class="input-group">
            <input
              autocomplete="off"
              type="number"
              v-model.number="LANDSCAPE.SQUAREFEET_LANDSCAPE_INSTALLED"
              name="l-sli"
              required
            />
            <div class="unit">Quantity</div>
            <label for="l-sli">Squarefeet Landscape Installed</label>
          </div>

          <div class="button-group">
            <div class="btn prev" @click.prevent.stop="scrollTo(0)">
              <ArrowUp /> Previous
            </div>
            <div
              class="btn next"
              :class="{
                disabled: false,
              }"
              @click.prevent.stop="scrollTo(2)"
            >
              Next <ArrowDown />
            </div>
          </div>
        </div>

        <div
          class="form-group"
          tabindex="3"
          v-if="true || progress.items.Landscape.done"
        >
          <h2>Supply Chain</h2>

          <transition-group
            class="container"
            tag="div"
            @before-enter="beforeEnter"
            @enter="enter"
            @leave="leave"
          >
            <div
              class="shipment-group"
              v-for="(SHIPMENT, name) of SUPPLY_CHAIN.SHIPMENTS"
              :key="name"
            >
              <h3 class="legend">{{ name }}</h3>
              <Cancel
                :key="'cancel-btn-' + name"
                class="cancel"
                style="left: 12px; top: 12px"
                @click="
                  $set(SUPPLY_CHAIN.SHIPMENTS, name, undefined);
                  delete SUPPLY_CHAIN.SHIPMENTS[name];
                "
              />
              <div
                :class="{ material: true, selected: material.Name !== 'None' }"
                v-for="(material, i) in SHIPMENT.ITEMS"
                :key="material.id"
              >
                <!-- <h4 class="legend" key="header">Material {{ material.id }}</h4> -->
                <!-- <Cancel
                  :key="'cancel-btn-' + i"
                  class="cancel"
                  style="right: -5px; top: -7px"
                  @click="SUPPLY_CHAIN.MATERIALS.splice(i, 1)"
                /> -->
                <div class="inner-group" key="inner-group">
                  <Select
                    :list="materials"
                    :option="{ key: 'Name' }"
                    :search="false"
                    :label="'Material'"
                    @select="
                      Object.assign(
                        SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i],
                        $event
                      )
                    "
                    :selected="SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i]"
                  />

                  <div class="input-group sc-mq">
                    <input
                      autocomplete="off"
                      type="number"
                      name="sc-mq"
                      v-model.number="
                        SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].quantity
                      "
                    />
                    <div class="unit">Square Meter</div>
                    <label for="sc-mq">Quantity</label>
                  </div>

                  <div
                    class="input-group"
                    v-if="
                      ['MDF', 'Marble'].includes(
                        SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].Name
                      )
                    "
                  >
                    <input
                      autocomplete="off"
                      type="number"
                      name="sc-mh"
                      v-model.number="
                        SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].width
                      "
                    />
                    <div class="unit">Meters</div>
                    <label for="sc-mh">Width</label>
                  </div>
                </div>

                <transition-group
                  :class="{
                    legs: true,
                    empty: material.legs.length == 0,
                  }"
                  tag="div"
                  @before-enter="beforeEnter"
                  @enter="enter"
                  @leave="leave"
                >
                  <h4 class="legend" key="legend">Transportation</h4>
                  <!-- <div class="ship-type" key="ship-type" v-if="false">
                    <div class="input-group">
                      <input
                        type="radio"
                        checked
                        :name="`ss-${i}`"
                        @click="
                          SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i]['Shipped Together'] = true
                        "
                      />
                      <label :for="`ss-${i}`">Shipped Together</label>
                    </div>

                    <div class="input-group">
                      <input
                        type="radio"
                        :name="`ss-${i}`"
                        @click="
                          SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i]['Shipped Together'] = false
                        "
                      />
                      <label :for="`ss-${i}`">Shipped Separately</label>
                    </div>
                  </div> -->
                  <div
                    class="leg"
                    :style="`z-index: ${
                      SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs.length - b
                    }`"
                    v-for="(leg, b) in SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i]
                      .legs"
                    :key="leg.id"
                  >
                    <h5>Leg {{ b + 1 }}</h5>
                    <Cancel
                      class="cancel"
                      @click="
                        SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs.splice(b, 1)
                      "
                    />

                    <Select
                      :list="transportations"
                      :option="{ key: 'Mode' }"
                      :search="false"
                      :label="'Transportation Mode'"
                      @select="
                        Object.assign(
                          SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b],
                          $event
                        )
                      "
                      :selected="SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b]"
                    />
                    <div class="input-group">
                      <input
                        autocomplete="off"
                        type="number"
                        name="sc-ld"
                        v-model.number="
                          SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b].distance
                        "
                      />
                      <div
                        class="unit"
                        v-text="
                          SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs[b].Unit
                        "
                      />
                      <label for="sc-mq">Distance</label>
                    </div>
                  </div>

                  <div
                    style="max-width: fit-content; font-size: 12px"
                    key="redirect-url"
                    v-if="SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs.length"
                  >
                    <a href="https://www.distancefromto.net/" target="_blank"
                      ><b
                        >Refer to this website to calculate distance
                        <Redirect width="16px" heigh="16px" fill="#333" /></b
                    ></a>
                  </div>
                </transition-group>
                <div
                  class="btn add-new"
                  title="Add new leg"
                  @click="
                    SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs.push(newLeg())
                  "
                  v-if="SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i].legs.length < 2"
                >
                  <span>Add new leg</span>
                </div>
              </div>
            </div>
          </transition-group>

          <div
            key="add-btn"
            class="btn add-new btn-material"
            title="Add new shipment"
            style="margin-bottom: 50px"
            @click="addNewShipment()"
            v-if="Object.keys(SUPPLY_CHAIN.SHIPMENTS).length < 25"
          >
            <span>Add Shipment</span>
          </div>

          <div class="button-group">
            <div class="btn prev" @click.prevent.stop="scrollTo(1)">
              <ArrowUp /> Previous
            </div>
            <div
              class="btn next"
              :class="{
                disabled: false,
              }"
              @click.prevent.stop="scrollTo(3)"
            >
              Results <ArrowDown />
            </div>
          </div>
        </div>

        <div class="form-group" tabindex="4">
          <h2>Results</h2>
          <div class="input-group">
            <input
              autocomplete="off"
              type="number"
              readonly
              name="tb"
              :value="TOTAL_BUILDING().toFixed(1)"
              valid
            />
            <div class="unit">Tonnes CO2 equiv</div>
            <label for="tb">Total Building</label>
          </div>

          <div class="input-group">
            <input
              type="text"
              readonly
              name="ts"
              :value="TOTAL_SITE().toFixed(1)"
              valid
              placeholder="Not ready"
            />
            <div class="unit">Tonnes CO2 equiv</div>
            <label for="ts">Total Site</label>
          </div>

          <div class="input-group has-sub-items">
            <input
              type="text"
              readonly
              name="tsc"
              :value="TOTAL_SUPPLY_CHAIN().value.toFixed(1)"
              valid
              placeholder="Not ready"
            />
            <div class="unit">Tonnes CO2 equiv</div>
            <label for="tsc">Total Supply Chain</label>

            <div class="sub-items">
              <div
                class="item"
                v-for="(ITEM, i) in TOTAL_SUPPLY_CHAIN().MATERIALS.filter(
                  (m) => m.Material !== 'None'
                )"
                :key="i"
              >
                <span>
                  {{ ITEM.Material }}
                  <small class="legs-text">
                    <div v-for="(t, i) in ITEM.Transportations" :key="i">
                      {{ t }} <Next width="8px" height="8px" />
                    </div>
                  </small>
                </span>
                <span>{{ ITEM["Sum Tonnes Co2"] }}</span>
              </div>
            </div>
          </div>

          <div class="input-group">
            <input
              type="text"
              readonly
              name="tb"
              :value="
                (
                  TOTAL_BUILDING() +
                  TOTAL_SITE() +
                  TOTAL_SUPPLY_CHAIN().value
                ).toFixed(3)
              "
              valid
              placeholder="Not ready"
            />
            <div class="unit">Tonnes CO2 equiv</div>
            <label for="tb">Summary</label>
          </div>

          <div class="button-group">
            <div class="btn prev" @click.prevent.stop="scrollTo(2)">
              <ArrowUp /> Previous
            </div>
            <div class="btn next" @click.prevent.stop="resetForm()">
              Reset <Reset />
            </div>
          </div>
        </div>

        <Footer />
      </form>
    </div>
  </div>
</template>

<script>
const states = require.context("../datas", true, /[A-Za-z0-9-_,\s]+\.json$/i);
const files = {};
states.keys().forEach((key) => {
  const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  if (matched && matched.length > 1) {
    const filename = matched[1];
    files[filename] = states(key);

    console.log(matched[1], "module installed");
  }
});
import Footer from "./footer";
import Select from "./inputs/select";
import Velocity from "velocity-animate";
export default {
  components: {
    Footer,
    Select,
    BuildingMeasurement: require("../assets/icons/building-measurement.svg"),
    Landscape: require("../assets/icons/landscape.svg"),
    Container: require("../assets/icons/container.svg"),
    Delivery: require("../assets/icons/delivery.svg"),
    Cancel: require("../assets/icons/cancel.svg"),
    ArrowUp: require("../assets/icons/up-arrow.svg"),
    ArrowDown: require("../assets/icons/down-arrow.svg"),
    Reset: require("../assets/icons/reset.svg"),
    Next: require("../assets/icons/next.svg"),
    Redirect: require("../assets/icons/redirect.svg"),
  },
  data() {
    return {
      ...files,
      scrollIndex: 0,
      scrollTop: 0,
      BUILDING_SIZE: {
        TOTAL_SQUARE_FEET: null, // number
        STORIES_ABOVE_GROUND: null, // number
      },
      LANDSCAPE: {
        LOCATION: null, // string
        EXISTING_VEGETATION: null, // string
        PLANTED_VEGETATION: null, // string
        SQUAREFEET_LANDSCAPE_INSTALLED: null, //number
      },
      SUPPLY_CHAIN: {
        SHIPMENTS: {},
      },
      progress: {
        value: 0,
        items: {
          Building: {
            icon: "BuildingMeasurement",
            done: false,
          },
          Landscape: {
            icon: "Landscape",
            done: false,
          },
          Materials: {
            icon: "Container",
            done: false,
          },
          Delivery: {
            icon: "Delivery",
            done: false,
          },
        },
      },
    };
  },
  methods: {
    // total calculations
    TOTAL_BUILDING() {
      let median = this.STORIES_ABOVE_GRADE(
        this.BUILDING_SIZE.STORIES_ABOVE_GROUND
      );
      let square_meters = this.BUILDING_SIZE.TOTAL_SQUARE_FEET / 10.764;
      let net_embodied_carbon = (median * square_meters) / 1000;

      this.$set(this.BUILDING_SIZE, "TOTALS", {
        Median: median,
        "Square Meters": square_meters,
        "New Embodied Carbon": net_embodied_carbon,
      });

      return net_embodied_carbon;
    },
    TOTAL_SITE() {
      if (
        !this.LANDSCAPE.LOCATION ||
        !this.LANDSCAPE.LOCATION["Nature"] ||
        !this.LANDSCAPE.LOCATION["Nature"]["Soil carbon stock SSP126 (Tg)"]
      )
        return 0;

      let square_meters =
        this.LANDSCAPE.SQUAREFEET_LANDSCAPE_INSTALLED / 10.764;
      let carbon_stock = this.LANDSCAPE.LOCATION["Nature"][
        "Soil carbon stock SSP126 (Tg)"
      ];
      let gm2 = this.LANDSCAPE.LOCATION["g/m2"];
      if (!carbon_stock || !square_meters) return 0;

      let total_soil_c_tonnes_co2 = (square_meters * gm2 * 44) / 12 / 1000000;
      let existing_luc = this.LANDSCAPE.EXISTING_VEGETATION["Land Use"];
      let planted_luc = this.LANDSCAPE.PLANTED_VEGETATION["Land Use"];
      let total_plantings_tonnes_co2 =
        planted_luc * this.NET_ANNUAL_CARBON("PLANTED_VEGETATION") -
        existing_luc * this.NET_ANNUAL_CARBON("EXISTING_VEGETATION");
      let total_landscape_tonnes_co2 =
        -1 * (total_soil_c_tonnes_co2 + total_plantings_tonnes_co2);

      // explicit total
      this.$set(this.LANDSCAPE, "TOTALS", {
        "Soil carbon stock SSP126 (Tg)": carbon_stock,
        "Existing Luc": existing_luc,
        "Planted Luc": planted_luc,
        "Total Plantings tonnes Co2": total_plantings_tonnes_co2,
        "Total Landscape Tonnes Co2": total_landscape_tonnes_co2,
      });
      return total_landscape_tonnes_co2;
    },
    TOTAL_SUPPLY_CHAIN() {
      let MATERIAL_TOTALS = [];
      let result = 0;

      Object.keys(this.SUPPLY_CHAIN.SHIPMENTS).forEach((name) => {
        let embodied_tonnes_co2 = 0;
        let legs_transport_co2 = 0;
        this.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS.forEach((MATERIAL,i) => {
          if (["MDF", "Marble"].includes(MATERIAL.Name)) {
            embodied_tonnes_co2 =
              ((MATERIAL.quantity / MATERIAL.width) *
                MATERIAL["kgCO2e per kg"]) /
              1000;
          } else {
            embodied_tonnes_co2 =
              (MATERIAL.quantity * MATERIAL["kgCO2e per kg"]) / 1000;
          }

          // calculate legs
          legs_transport_co2 = MATERIAL.legs.reduce((co2, LEG) => {
            if (LEG.Mode === "Ocean") {
              let freight = {
                km: LEG.distance * 1.852,
                "m3/TEU": 32.7,
                "Utilization Factor": 0.7,
              };
              freight["Volume (# of TEU's)"] =
                MATERIAL.quantity / freight["m3/TEU"];
              freight["grams CO2 per TEU*km"] =
                58.3 / freight["Utilization Factor"];
              freight["g CO2"] =
                freight["km"] *
                freight["Volume (# of TEU's)"] *
                freight["grams CO2 per TEU*km"];
              freight["tonnes CO2"] = freight["g CO2"] / 1000000;

              co2 += freight["tonnes CO2"];
            } else {
              let tone_x_miles =
                (LEG.distance *
                  MATERIAL["Unit Conversion (tonnes/m3)"] *
                  MATERIAL.quantity) /
                1000;

              co2 += (tone_x_miles * LEG["kg CO2e/ton-mile"]) / 1000;
            }
            return co2;
          }, 0);

          let item = {
            Shipment: name,
            Material: MATERIAL.Name,
            Transportations: MATERIAL.legs.map((leg) => leg.Mode),
            "Embodied Tonnes Co2": embodied_tonnes_co2,
            "Transport Co2": legs_transport_co2,
            "Sum Tonnes Co2": Number((
              embodied_tonnes_co2 + legs_transport_co2
            ).toFixed(3)),
          }

          this.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i] = Object.assign(this.SUPPLY_CHAIN.SHIPMENTS[name].ITEMS[i], item)
          MATERIAL_TOTALS.push(item);

          result += embodied_tonnes_co2 + legs_transport_co2;
        });
      });

      return {
        value: result,
        MATERIALS: MATERIAL_TOTALS,
      };
    },

    // helper functions
    STORIES_ABOVE_GRADE(number) {
      if (number > 0 && number < 7) return 438;
      else if (number > 6 && number < 15) return 430;
      else if (number > 14 && number < 26) return 173;
      else return 695;
    },
    ANNUAL_GROWTH_INCREMENT(LANDSCAPE_VEGETATION) {
      if (
        !this.LANDSCAPE[LANDSCAPE_VEGETATION] ||
        !this.LANDSCAPE[LANDSCAPE_VEGETATION]["Increment In Biomass"]
      )
        return 0;

      let area_kha =
        this.LANDSCAPE.SQUAREFEET_LANDSCAPE_INSTALLED / 10.764 / 10000000;
      let annual_growth_rate = this.LANDSCAPE[LANDSCAPE_VEGETATION][
        "Increment In Biomass"
      ];
      let carbon_fraction_dry_matter = 0.5;
      let annual_biomass_increment = area_kha * annual_growth_rate;
      let total_carbon_uptake_increment =
        annual_biomass_increment * carbon_fraction_dry_matter * 1000;

      Object.assign(this.LANDSCAPE[LANDSCAPE_VEGETATION], {
        "Annual Growth Rate": annual_growth_rate,
        "Annual Biomass Increment": annual_biomass_increment,
        "Carbon Fraction Dry Matter": carbon_fraction_dry_matter,
        "Total Carbon Uptake Increment": total_carbon_uptake_increment,
      });

      return total_carbon_uptake_increment || 0;
    },
    NET_ANNUAL_CARBON(LANDSCAPE_VEGETATION) {
      let annual_growth_increment = this.ANNUAL_GROWTH_INCREMENT(
        LANDSCAPE_VEGETATION
      );
      let net_annual_carbon_uptake_or_release =
        annual_growth_increment > 0
          ? annual_growth_increment -
            this.BIOMASS_HARVESTED(LANDSCAPE_VEGETATION)
          : 0;
      let annual_co2_removal = (net_annual_carbon_uptake_or_release * 44) / 12;

      Object.assign(this.LANDSCAPE[LANDSCAPE_VEGETATION], {
        "Annual Growth Increment": annual_growth_increment,
        "Net Annual Carbon Uptake Or Release": net_annual_carbon_uptake_or_release,
        "Annual Co2 Removal": annual_co2_removal,
      });

      return annual_co2_removal;
    },
    BIOMASS_HARVESTED(LANDSCAPE_VEGETATION) {
      let harvest_area_kha =
        this.LANDSCAPE.SQUAREFEET_LANDSCAPE_INSTALLED / 10.764 / 10000000;
      let biomass_conversion_expansion_ratio = 0.5;
      let total_biomass_removed =
        harvest_area_kha * biomass_conversion_expansion_ratio * 1000;

      Object.assign(this.LANDSCAPE[LANDSCAPE_VEGETATION], {
        "Harvest Area KHA": harvest_area_kha,
        "Biomass Conversion Expansion Ratio": biomass_conversion_expansion_ratio,
        "Total Biomass Removed": total_biomass_removed,
      });

      return total_biomass_removed;
    },
    scrollTo(index) {
      this.scrollIndex = index;
      const scrollBody = document.querySelector(".body");
      let height = 0;

      document.querySelectorAll(".form-group").forEach((group, i) => {
        if (index > i) height += group.clientHeight;
      });

      scrollBody.scrollTop = height;
    },
    addNewShipment() {
      let letter = "ABCDEFGHIJKLMNOPRSTUVWXYZ";
      if (Object.keys(this.SUPPLY_CHAIN.SHIPMENTS).length < 25) {
        this.$set(
          this.SUPPLY_CHAIN.SHIPMENTS,
          `Shipment ${letter[Object.keys(this.SUPPLY_CHAIN.SHIPMENTS).length]}`,
          {
            TOTALS: {},
            ITEMS: Array(6)
              .fill(null)
              .map(() => this.newMaterial()),
          }
        );
      }
    },
    newMaterial() {
      return {
        id: parseInt(Math.random() * 1000000),
        ...this.materials.find((material) => material.Name === "None"),
        // "Shipped Together": true,
        quantity: 0, // square meter
        width: 0, // meters
        legs: [this.newLeg()],
      };
    },
    newLeg() {
      return {
        id: parseInt(Math.random() * 1000000),
        ...this.transportations.find((transport) => transport.Mode === "None"),
        distance: 0, // km
      };
    },
    find(from, what) {
      let key = Object.entries(what)[0];
      let value = Object.entries(what)[1];
      if (typeof this[from] !== "undefined") {
        return this[from].find((item) => item[key] === value);
      }
      return false;
    },
    resetForm() {
      this.scrollTo(0);
      this.setProgressItems();
      setTimeout(() => {
        this.$set(this, "BUILDING_SIZE", {
          TOTAL_SQUARE_FEET: null, // number
          STORIES_ABOVE_GROUND: null, // number
        });
        this.$set(this, "LANDSCAPE", {
          LOCATION: null, // string
          EXISTING_VEGETATION: null, // string
          PLANTED_VEGETATION: null, // string
          SQUAREFEET_LANDSCAPE_INSTALLED: null, //number
        });
        this.$set(this, "SUPPLY_CHAIN", {
          SHIPMENTS: {},
        });
      }, 600);
    },
    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transformOrigin = "left";
    },
    enter: function (el, done) {
      Velocity(el, { opacity: 1, translateY: "10px" }, { duration: 100 });
      Velocity(el, { opacity: 1, translateY: "0px" }, { complete: done });
    },
    leave: function (el, done) {
      Velocity(el, { translateY: "0px" }, { duration: 200 });
      Velocity(
        el,
        {
          translateY: "-10px",
          opacity: 0,
        },
        { complete: done }
      );
    },
    setProgressItems() {
      let index = 0;
      let interval = setInterval(() => {
        if (index > document.querySelectorAll(`.progress > *`).length - 1) {
          clearInterval(interval);
        } else {
          document
            .querySelectorAll(`.progress > *`)
            [index].classList.add("set");
          index++;
        }
      }, 500);
    },
    calcScrollIndex(scrollTop) {
      this.scrollTop = scrollTop;
      let sum = 0;
      document.querySelectorAll(".form-group").forEach((item, i) => {
        sum += item.clientHeight;
        // 937 1874 2811

        if (scrollTop >= sum) {
          this.scrollIndex = i + 1;
        } else if (i === 0) this.scrollIndex = 0;
      });
    },
  },
  mounted() {
    document.querySelector(".form-group").focus();

    this.countries = this.countries
      .map((country) => {
        if (country.code != "US") {
          country.disabled = true;
        } else country.disabled = false;
        return country;
      })
      .sort((a, b) => a.disabled - b.disabled);

    this.setProgressItems();
  },
};
</script>

<style lang="sass" scoped>
$primary: lighten(black, 70%)
$secondary: lighten(black, 95%)

@for $i from 1 through 5
  html h#{$i}
    padding: 0
    text-transform: uppercase
    font-size: 2em - $i*0.32em
    margin: 0
    z-index: 2
    scroll-snap-align: start
@media screen and (min-width: 1920px)
  @for $i from 1 through 5
    html h#{$i}
      font-size: 2em - $i*0.2em

.body
  overflow: auto
  -webkit-overflow-scroll: touch
  @supports (overflow: overlay)
    overflow: overlay
  header
    scroll-snap-align: start
    display: flex
    justify-content: flex-start
    align-items: center
    position: relative
    z-index: 12
    box-shadow: inset 0 0 12px #eee
    background-color: rgba(0,0,0,.001)
    transition: top .75s ease
    top: 0
    &.scrolled
      top: -250px
    .group
      display: flex
      align-items: center
      padding: 1rem
      img.logo
        width: 120px
        height: 100%
        border-right: 1px solid
        padding-right: 1rem
        margin-right: 1rem
      > h1
        background-color: rgba(0,0,0,.001)
        white-space: nowrap
        @media screen and (max-width: 500px)
          font-size: medium
        @media screen and (max-width: 360px)
          font-size: small
  .progress
    display: flex
    flex: 1
    align-items: center
    justify-content: space-evenly

    position: fixed
    top: 67px
    width: calc( 100% - 4px )

    padding: 1rem
    background-color: rgba(255,255,255,.95)
    z-index: 11
    box-shadow: 0 0 12px transparent
    transition: box-shadow .2s ease, top .2s ease
    &.scrolled
      top: 0
      box-shadow: 0 0 12px lighten($secondary, 0%)
    .track
      height: 4px
      width: 90%
      background-color: $secondary
      box-shadow: inset 0 0 1px #bbb
      position: absolute
      z-index: 0
      top: 50px
      @media screen and (min-width: 1920px)
        top: 65px
      @media screen and (max-width: 500px)
        top: 40px
      @media screen and (max-width: 400px)
        width: 100%
      &::before, &::after
        content: ""
        border-radius: 50%
        width: 6px
        height: 6px
        background-color: white
        border: 2px solid $primary
        display: block
        position: absolute
        margin-top: -3px
        z-index: 2
        @media screen and (max-width: 400px)
          content: none
      &::before
        left: 0
      &::after
        right: 0
      .bar
        position: absolute
        left: 0
        right: 0
        height: 4px
        background-color: $primary
        transition: width .2s ease
        z-index: 1
    .item
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      z-index: 2
      transition: transform .2s ease-in
      transform: perspective(20px) rotateX(1deg) rotateY(1deg)
      &.set
        transform: perspective(1000px) rotateX(1deg) rotateY(1deg)
        *
          animation: set-anim 2s ease-out
      @keyframes set-anim
        from
          // fill: black
          color: black
        to
          // fill: $primary
          color: $primary
      svg
        padding: 6px
        border-radius: 4px
        background-color: #fff
        // border: 1px solid $secondary
        width: 70px
        height: 70px
        fill: $primary
        transition: fill .2s ease
        .st0
          fill: white
        @media screen and (max-width: 500px)
          width: 50px
          height: 50px
        @media screen and (min-width: 1920px)
          width: 100px
          height: 100px
      h3
        color: $primary
        @media screen and (max-width: 400px)
          font-size: 80%
        @media screen and (min-width: 1920px)
          margin-top: 8px
    .item.done
      transform: unset
      svg
        // border: 1px solid $primary
        fill: #000
      h3
        color: #000
  // progress end //
  form
    position: absolute
    width: 100%
    top: 0
    .form-group
      scroll-snap-align: start
      height: 100vh
      min-height: 100vh
      max-height: auto
      flex: 1
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      position: relative
      background-size: contain
      background-repeat: no-repeat
      background-attachment: fixed
      background-position: center
      outline: none
      @media screen and (max-width: 430px)
        padding: 10px
      &::after
        position: absolute
        top: 0
        width: 100%
        height: 100%
        content: ""
        display: block
        background-color: rgba(255,255,255,.94)
      &:nth-child(1)
        background-image: url('../assets/images/buildings.jpg')
      &:nth-child(2)
        background-image: url('../assets/images/vegetations.jpg')
      &:nth-child(3)
        background-image: url('../assets/images/cargo.jpg')
        height: auto
        // padding-top: 200px
        // @media screen and (max-height: 800px)
          // padding-top: 120px
          // height: auto
      &:nth-child(4)
        background-image: url('../assets/images/carbon-footprint.jpg')
      .input-group.has-sub-items
        .sub-items
          margin-bottom: 10px
          width: 300px
          margin-top: 8px
          @media screen and (min-width: 1920px)
            width: 400px
          .item
            display: flex
            margin-left: 20px
            position: relative
            @media screen and (min-width: 1920px)
              font-size: 18px
            &::before
              content: ""
              display: block
              position: absolute
              background-color: $primary
              height: 1px
              width: 6px
              left: -8px
              top: 12px
            &::after
              content: ""
              display: block
              position: absolute
              background-color: $primary
              height: 20px
              width: 1px
              left: -8px
              top: -8px
            span:first-child
              font-weight: bold
              min-width: 160px
              display: flex
              align-items: flex-start
              color: #666
              .legs-text
                color: #888
                font-size: 10px
                display: flex
                margin-left: 6px
                @media screen and (min-width: 1920px)
                  font-size: 14px
                > div
                  display: flex
                  align-items: center
                  svg
                    margin: 0 4px
                  &:last-child svg
                    display: none
            span:last-child
              font-weight: 300
              position: absolute
              right: 0
    .button-group
      z-index: 1
      display: flex
      min-width: auto
      width: 300px
      max-width: auto
      justify-content: space-between
      align-items: center
      @media screen and (max-width: 400px)
        width: -webkit-fill-available
        margin: 0 10px
      @media screen and (min-width: 1920px)
        width: 400px
        font-size: 20px
        .btn
          width: 140px !important
          padding: .5rem 1rem !important
      .btn
        width: 80px
        padding: .25rem
        border-radius: 4px
        background-color: black
        color: white
        display: flex
        flex-direction: column
        &.disabled
          opacity: .1
          pointer-events: none
        svg
          width: 20px
          height: 20px
          fill: white
          margin: 0
    .container
      z-index: 2
      position: relative
      display: flex
      justify-content: center
      flex-direction: column
      // .materials
      //   width: 100%
      //   position: relative
      //   padding: 0rem 2rem
      //   margin-top: 1rem
      //   flex-direction: row
      //   justify-content: center
      //   display: flex
      //   flex-wrap: wrap
      //   text-align: center
      //   // grid-gap: 1rem
      //   // grid-template-columns: repeat( auto-fill, minmax(300px, auto) )
      //   @media screen and (max-height: 800px)
      //     flex-wrap: wrap
      .shipment-group
        display: flex
        flex-wrap: wrap
        // width: 100%
        margin: 3rem 1rem
        padding: 1rem
        justify-content: unset
        position: relative
        border: 5px dashed $secondary
        border-radius: 8px
        .cancel
          z-index: 1
        h3.legend
          position: absolute
          left: 30px
          top: -1px
          background-color: white
          padding: .25rem
        .material
          min-width: 280px
          border: 1px solid transparent
          background: transparent
          border-radius: 4px
          padding: 1.5rem
          margin: 1.5rem
          position: relative
          transition: border .2s, background .2s
          &:not(.selected) .inner-group ~ *, &:not(.selected) .sc-mq
            display: none
          &.selected
            border: 1px solid $primary
            background: #fff
            .inner-group ~ *, .sc-mq
              display: auto
          .input-group
            input
              min-width: none
              width: 100%
          h4.legend
            position: absolute
            left: 1rem
            top: -1rem
            background-color: white
            padding: .25rem
          .custom-select
            width: auto
          .inner-group
            margin: 0
          .legs
            position: relative
            white-space: pre-line
            background-color: #fafafa
            border-radius: 4px
            border: 1px solid $secondary
            margin-top: 6px
            padding: 1rem
            display: flex
            flex-direction: column
            &.empty
              background-color: transparent
              border-color: transparent
              h4.legend
                background-color: transparent
            .input-group
              z-index: inherit
              label, .legend
                background-color: #fafafa
            .custom-select
              min-width: auto !important
            .leg
              position: relative
              h5
                text-align: left
              h5::after
                content: ""
                position: absolute
                height: 1px
                width: 70%
                background-color: $secondary
                top: 9px
    .add-new
      width: fit-content
      margin: 1rem 0
      z-index: 1
      span
        font-size: 14px
        color: black
        padding: 4px 8px
        @media screen and (min-width: 1920px)
          font-size: 20px
          padding: 8px 16px
      svg
        width: 26px
        height: 26px
        .st0
          fill: white
</style>