<template>
  <div
    class="custom-select"
    @click.stop.prevent="selectbox = !selectbox"
    ref="custom-select"
    :id="id"
  >
    <div :class="{ 'selected-item': true, selected }" ref="selected-item">
      <div :class="{ item: true, active: selectbox }">
        {{ selected ? selected[option.key] : null }}
      </div>
      <label>{{ label }}</label>
    </div>

    <div @mouseup.stop class="selectbox" v-if="selectbox" key="selectbox">
      <div class="search" v-if="search">
        <input
          type="search"
          v-model.trim="searchText"
          placeholder="Search"
          @click.prevent.stop="$event.target.select()"
          @keyup.esc="selectbox = false"
        />
      </div>
      <div class="list">
        <div
          :class="{ option: true, disabled: item.disabled }"
          v-for="(item, index) in searchText
            ? list.filter((item) => searchList(item))
            : list"
          :key="index"
          @click.prevent.stop="select(item)"
        >
          <span class="name" v-if="option && option.key && item[option.key]">{{
            item[option.key]
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    option: Object,
    label: {
      type: String,
      default: "Label Text",
    },
    search: {
      type: Boolean,
      default: false,
    },
    selected: Object,
  },
  data() {
    return {
      id: `cs-${String(Math.random() * 100).slice(-10)}`,
      searchText: null,
      selectbox: false,
    };
  },
  methods: {
    searchList(item) {
      return String(item[this.option.key])
        .toUpperCase()
        .includes(String(this.searchText).toUpperCase());
    },
    select(item) {
      if (!item.disabled) {
        this.selectbox = false;

        this.$emit("select", item);
      }
    },
  },
  mounted() {
    document
      .querySelector(`:not(#${this.id})`)
      .addEventListener("mouseup", () => {
        this.selectbox = false;
      });
    document.querySelector(".body").addEventListener("scroll", () => {
      this.selectbox = false;
    });
  },
  watch: {
    selectbox(selectbox) {
      if (selectbox)
        setTimeout(() => {
          let dropdownOffsetTop = document
            .querySelector(".selectbox")
            .parentElement.getBoundingClientRect().y;
          let screenHeight = document.querySelector('.body').clientHeight;
          let maxheight = screenHeight - dropdownOffsetTop - 54 - 48 - 20;

          document.querySelector(".selectbox > .list").style["max-height"] =
            maxheight + "px";
        }, 250);
    },
  },
};
</script>

<style lang="sass" scoped>
$primary: lighten(black, 55%)
$secondary: lighten(black, 95%)

.custom-select
  text-align: left
  width: 300px
  position: relative
  outline: none
  .selected-item
    cursor: pointer
    z-index: 1
    &.selected
      label
        position: absolute
        display: inline-block
        background-color: #fafafa // header background
    .item
      padding: 6px 4px
      border: 1px solid #eee
      width: 100%
      border-radius: 4px
      min-height: 34px
      background-color: #fff
      transition: border .2s ease, background .1s ease, box-shadow .2s
      &:active
        background-color: #eee
        border: 1px solid #aaa
        box-shadow: 0 0 12px #ddd
    .item.active
      border: 1px solid #aaa
  .selectbox
    position: absolute
    z-index: 10
    width: 100%
    top: 52px
    background-color: #fff
    box-shadow: 0 0 20px #f1f1f1
    border-radius: 8px
    border: 1px solid #eee
    .search
      input[type="search"]
        width: calc( 100% - 1rem )
        border: 1px solid #f1f1f1
        border-radius: 4px
        padding: 6px 4px
        background-color: white
        margin: .5rem
        outline: none
        &:focus
          border: 1px solid #ccc
    .list
      border-top: 1px solid #eee
      overflow: auto
      height: 100%
      @supports (overflow: overlay)
        overflow: overlay
      .option
        padding: 4px 1rem
        border-top: 1px solid #eee
        cursor: pointer
        background-color: #fff
        transition: background-color .2s ease
        &:hover
          background-color: #eee
        &:first-child
          border-top: none
        &:active
          background-color: #ccc
          color: white
      .option.disabled
        background-color: #fafafa
        color: #999
        border-top: 1px solid #eee
        cursor: not-allowed
    .list
      &:hover::-webkit-scrollbar-thumb
        background-color: $primary
      &::-webkit-scrollbar
        width: 4px
        &-track
          background-color: white
        &-thumb
          border-radius: 4px
          background-color: white
          &:hover
            background-color: darken($primary, 20%)
  @media screen and (max-width: 430px)
      width: -webkit-fill-available
  @media screen and (max-width: 300px)
    .selectbox
      width: 90%
  @media screen and (min-width: 1920px)
    width: 400px
    .selected-item
      .item
        min-height: 41px
    .selectbox
      top: 65px
      .list .option
        padding: 10px 1rem
</style>